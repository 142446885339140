<template>
  <div :id="post.$id" class="cursor-pointer" onclick="showLightbox(this.id)" v-cloak>

    <div v-if="format == 'slider'" class="slider relative">
      <div class="relative overflow-hidden" :class="widget.postBorderWidth == 'border-0' ? widget.postBorderRadius : ''" @mouseover="hover = true" @mouseleave="hover = false">
        <span v-if="format != 'masonry' && video" class="ease-in-out duration-500 overlay-play absolute w-full text-center h-full flex items-center text-white">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" class="w-12 mx-auto">
            <g>
              <path fill="#111111" d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z"/>
              <path fill="#ffffff" d="M734.5,539.3L442.2,740.4c-8.1,5.6-17.6,8.4-27.1,8.4c-7.6,0-15.2-1.8-22.2-5.5c-15.7-8.3-25.5-24.6-25.5-42.3V298.9c0-17.7,9.9-34,25.5-42.3c15.7-8.2,34.7-7.1,49.3,3l292.3,201.1c12.9,8.9,20.7,23.6,20.7,39.3C755.2,515.7,747.4,530.4,734.5,539.3z"/>
            </g>
          </svg>
        </span>
        <transition v-else name="fade">
          <div v-if="hover" class="overlay absolute w-full h-full flex items-center text-white p-8">
            <div class="text-center">
              <span class="opacity-75 text-xs"><time-ago :refresh="60" :datetime="post.createdAt" long></time-ago></span>
              <div class="mt-1" v-html="$options.filters.removeOrphans(post.title)">{{ post.title }}</div>
              <span class="inline-block border border-gray-200 rounded-lg mt-3 px-4 py-2 text-sm opacity-70 hover:opacity-100">read more</span>
            </div>
          </div>
        </transition>
        <img :src="image + '&width=600&height=600&quality=60'" class="w-full"/>
      </div>

      <div class="p-5">
        <h1 v-if="!widget || widget.postTitle" class="text-lg font-semibold">{{ post.title }}</h1>
        <p v-if="!widget || widget.postCaption" class="my-2">{{ post.text }}</p>
        <div v-if="!widget || widget.propertyLogo || widget.propertyName" class="flex items-center mt-4">
          <div v-if="!widget || widget.propertyLogo" class="flex-none mr-3">
            <img v-if="avatar" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full shadow bg-gray-100 w-8 h-8"/>
            <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="inline-block w-8 h-8 bg-gray-300 rounded-full shadow"/>
          </div>
          <div class="leading-4 text-sm">
            <div v-if="!widget || widget.propertyName" class="truncate">{{property.name}}</div>
            <span class="opacity-50">
              <time-ago :refresh="60" :datetime="post.createdAt" long></time-ago>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else>

      <div class="relative" @mouseover="hover = true" @mouseleave="hover = false">
        <transition name="fade">
          <span v-if="format != 'masonry' && video" class="ease-in-out duration-500 overlay-play absolute w-full text-center h-full flex items-center text-white">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" class="w-12 mx-auto">
              <g>
                <path fill="#111111" d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z"/>
                <path fill="#ffffff" d="M734.5,539.3L442.2,740.4c-8.1,5.6-17.6,8.4-27.1,8.4c-7.6,0-15.2-1.8-22.2-5.5c-15.7-8.3-25.5-24.6-25.5-42.3V298.9c0-17.7,9.9-34,25.5-42.3c15.7-8.2,34.7-7.1,49.3,3l292.3,201.1c12.9,8.9,20.7,23.6,20.7,39.3C755.2,515.7,747.4,530.4,734.5,539.3z"/>
              </g>
            </svg>
          </span>
          <div v-else-if="hover && post.callToAction && post.callToActionLink" class="overlay absolute w-full h-full flex items-center text-white p-8">
            <div class="text-center">
              <span v-if="format == 'slider'" class="opacity-75 text-xs"><time-ago :refresh="60" :datetime="post.createdAt" long></time-ago></span>
              <div v-if="format == 'slider'" class="mt-1">{{ post.title }}</div>
              <a :href="post.callToActionLink" class="inline-block border border-gray-200 rounded-lg mt-3 px-4 py-2 text-sm opacity-70 hover:opacity-100" target="_blank">{{ post.callToAction }}</a>
            </div>
          </div>
        </transition>
        <img v-if="format == 'grid'" :src="image + '&width=500&height=333&quality=60'"/>
        <img v-else-if="format == 'masonry' && !video" :src="image + '&width=500&quality=60'"/>
        <video v-else-if="video" controls crossorigin="anonymous">
          <source :src="video" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="p-5">
        <h1 v-if="!widget || widget.postTitle" class="text-lg font-semibold">{{ post.title }}</h1>
        <p v-if="!widget || widget.postCaption" class="my-2">{{ post.text }}</p>
        <div class="flex items-center mt-4">
          <div v-if="!widget || widget.propertyLogo" class="mr-3">
            <img v-if="avatar" :src="avatar + '&width=150&height=150&quality=60'" class="rounded-full shadow bg-gray-100 w-8 h-8"/>
            <img v-else :src="'https://app.news.travel/v1/avatars/initials?project=60e70fa53301e&name=' + property.name" class="inline-block w-8 h-8 bg-gray-300 rounded-full shadow"/>
          </div>
          <div class="leading-4 text-sm">
            <div v-if="!widget || widget.propertyName" class="truncate">{{property.name}}</div>
            <span class="opacity-50">
              <time-ago :refresh="60" :datetime="post.createdAt" long></time-ago>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from '../api'
import { Server } from '../utils/config'
import { TimeAgo } from 'vue2-timeago'

export default {
  name: 'Post',
  components: {
    TimeAgo
  },
  props: ['post', 'widget', 'format'],
  data () {
    return {
      my_value: 'start',
      show: true,
      avatar: false,
      file: {},
      video: false,
      image: false,
      hover: false,
      property: {}
    }
  },
  async mounted () {
    this.image = api.fetchImage(this.post.image)
    if (this.post.video) {
      this.file = await api.fetchFile(this.post.video)
      this.video = api.downloadFile(this.file.$id)
    }
    this.property = await api.getDocument(Server.propertiesCollectionID, this.post.propertyId)
    if (this.property.avatarId) {
      this.avatar = api.fetchImage(this.property.avatarId)
    }
    this.my_value = this.post.$id
  },
  methods: {
    convertDate (epoch) {
      var date = new Date(epoch)
      return date.toLocaleString()
    }
  }
}
</script>

<style>

svg{
  filter: drop-shadow(0px 0px 8px rgba(155, 155, 155, 0.5));
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.overlay {
  background-color: rgba(17,24,39,0.75);
}
.overlay-play {
  background-color: rgba(17,24,39,0.25);
}
  .overlay-play:hover {
    background-color: rgba(17,24,39,0.75);
  }
.overlay-play * {
  opacity: 0.95;
}
  .overlay-play:hover * {
    opacity: 1;
  }
.overlay > div {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
